import React, { useState, useEffect } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../layout/Header/Header';
import UserHeader from '../../layout/User/UserHeader';
import 'remixicon/fonts/remixicon.css';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import Logo from '../../components/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { useMst } from '../../models';
import Popovers from '../../components/bootstrap/Popovers';
import Badge from '../../components/bootstrap/Badge';
import { observer } from 'mobx-react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';

const SearchHeader = observer(() => {
	const { user } = useMst();
	const [isNotiOffCanvasOpen, setIsNotiOffCanvasOpen] = useState(false);
	const [roleNames, setRoleNames] = useState([]);
	const [state3, setState3] = useState(true); //채팅방 정보 > 참여자 추가

	const navigate = useNavigate();
	const closeModalAndRedirect = (url, data) => {
		setState3(false);
		navigate(url, { state: data });
	};
	return (
		<>
			<Header>
				<HeaderLeft className='d-flex flex-row align-items-center'>
					<h1 className='brand-head'>
						<Link to='/home' aria-label='Logo' alt='CCP'>
							<Logo width={'100%'} />
						</Link>
					</h1>
				</HeaderLeft>
				<HeaderRight>
					<div className=''>
						<div className='w-auto d-flex flex-row align-items-center'>
							<div
								className='alram-box'
								onClick={() => {
									setIsNotiOffCanvasOpen(true);
								}}>
								<i className='ri-notification-2-line ri-xl'></i>
							</div>
							<UserHeader />
						</div>
					</div>
				</HeaderRight>
			</Header>

			<OffCanvas isOpen={isNotiOffCanvasOpen} setOpen={setIsNotiOffCanvasOpen}>
				<OffCanvasHeader setOpen={setIsNotiOffCanvasOpen}>
					<OffCanvasTitle id={'notiTitle'}>알림</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='d-flex h-50 justify-content-center align-items-center'>
						알림 내역이 없습니다.
					</div>
				</OffCanvasBody>
			</OffCanvas>
			{/* 서비스 메뉴 보여주기 */}
			<Modal isCentered={true} isOpen={state3} setIsOpen={setState3} isScrollable fullScreen>
				<ModalHeader setIsOpen={setState3} className='line-remove'>
					{/* <ModalTitle>참여자 추가</ModalTitle> */}
				</ModalHeader>
				<ModalBody>
					<h2 className='ta-c'>서비스 기능</h2>
					<p className='inner-subtitle'>플랫폼에서 제공하는 제조데이터를 확인하세요.</p>
					<ul className='system-box'>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/dash', { url: 'https://dx.idrenvision.com:8071' })
								}
								className='system-box-inner box01'>
								대시보드
							</div>
						</li>
						<li>
							<div
								onClick={() => closeModalAndRedirect('/chat/direct')}
								className='system-box-inner box02'>
								채팅
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/monitoring', {
										url: 'https://dx.idrenvision.com:8071',
									})
								}
								className='system-box-inner box03'>
								생산 모니터링
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/workDailyReport', {
										url: 'https://dx.idrenvision.com:8071',
									})
								}
								className='system-box-inner box04'>
								생산일보
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/inventory', {
										url: 'https://dx.idrenvision.com:8071',
									})
								}
								className='system-box-inner box05'>
								재고관리
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/simulator', {
										url: 'https://dx.idrenvision.com:8071',
									})
								}
								className='system-box-inner box06'>
								What-if simulator
							</div>
						</li>
						<li>
							<div
								onClick={async () => {
									window.open(
										'https://chat.teamply.co.kr/api/v1/oauth/authorize?client_id=43G4KIN5TPJOT2GTXM5MJLQKZHACA907&redirect_uri=https://korens.teamply.co.kr/callback&response_type=code&state=test',
										'teamply',
									);
								}}
								className='system-box-inner box07'>
								Work Manager
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/dash', { url: 'https://dx.idrenvision.com:8071' })
								}
								className='system-box-inner box08'>
								클레이독스
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/dash', { url: 'https://dx.idrenvision.com:8071' })
								}
								className='system-box-inner box09'>
								관계망
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/lineinfo', {
										url: 'https://dx.idrenvision.com:8071',
									})
								}
								className='system-box-inner box10'>
								공정정보
							</div>
						</li>
						<li>
							<div
								onClick={() =>
									closeModalAndRedirect('/links/dash', { url: 'https://dx.idrenvision.com:8071' })
								}
								className='system-box-inner box11'>
								작업기준정보
							</div>
						</li>
					</ul>
				</ModalBody>
			</Modal>
		</>
	);
});

export default SearchHeader;
