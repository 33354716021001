import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody, CardFooter } from '../../components/bootstrap/Card';
import { userMenu } from '../../menu';
import Avatar from '../../components/Avatar';
import { useFormik } from 'formik';
import { useMst } from '../../models';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import { observer } from 'mobx-react';
import LoadingSpinner from '../Auth/component/LoadingSpinner';
import Input from '../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Label from '../../components/bootstrap/forms/Label';
import Textarea from '../../components/bootstrap/forms/Textarea';
import Collapse from '../../components/bootstrap/Collapse';
import { commonValidationAttribute, getLoginData } from '../../lib/Util';
import Icon from '../../components/icon/Icon';
import { logoutOtherClients, resetAvatar, setAvatar, updateOwnBasicInfo } from '../chat/Service';
import showNotification from '../../components/extras/showNotification';
import moment from 'moment';
import Popovers from '../../components/bootstrap/Popovers';
import { isMessengerPasswordValid } from '../../lib/checker';
import md5 from 'md5';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import Progress from '../../components/bootstrap/Progress';
import { motion } from 'framer-motion';
import RocketChatService from '../../services/RocketChatService';
import useEmailExpirationTime from '../../hooks/useEmailExpirationTime';
import { getItem } from '../../lib/localstorage';
import { useLocation } from 'react-router-dom';

const NAME = 'name';
const EMAIL = 'email';
const STATUS_TEXT = 'statusText';
const BIO = 'bio';
const NEW_PASSWORD = 'newPassword';
const CONFIRM_NEW_PASSWORD = 'confirmNewPassword';
const AVATAR_URL = 'avatarUrl';
const DELETE_TIME = 3; // s
const DELETE_INTERVAL_TIME = 1000; // ms
const VERIFICATION_CODE = 'verificationCode';
const IS_VERIFICATION_CODE_CONFIRMED = 'isVerificationCodeConfirmed';
const IS_SUBMITTED_VERIFICATION_CODE = 'isSubmittedVerificationCode';
const DELETEUESR = 'DELETEUSER';

const ProfileSettings = observer((props) => {
	const { user } = useMst();
	const fileHandleRef = useRef();
	const avatarRef = useRef();
	const [isPressingDeleteButton, setIsPressingDeleteButton] = useState(false);
	const [progress, setProgress] = useState(0);
	const [isRequesting, setIsRequesting] = useState(false);
	const [isConfirmDeleteMeModalOpen, setIsConfirmDeleteMeModalOpen] = useState(false);
	const { isIntervalRunning, doClearInterval, getExpirationTime, renderRemainingTime } =
		useEmailExpirationTime();

	const [token, setToken] = useState('');
	const location = useLocation();

	useEffect(() => {
		let accessToken = getItem('access-token');
		let refreshToken = getItem('refresh-token');

		console.log(location);

		const t = btoa(JSON.stringify({ accessToken, refreshToken }));

		if (accessToken && refreshToken) setToken(t);
	}, [user.me, location, props]);

	return (
		<>
			{token && (
				<iframe
					src={`${
						location?.state?.url || 'https://dx.idrenvision.com:8071'
					}${location.pathname.replace('/links', '')}?token=${token}`}
					style={{ width: 10000, height: 'calc(100vh - 4.25rem)' }}
				/>
			)}
		</>
	);
});

export default ProfileSettings;
