import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card, { CardBody, CardFooter, CardHeader } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { useFormik } from 'formik';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Label from '../../components/bootstrap/forms/Label';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import Checks from '../../components/bootstrap/forms/Checks';
import Collapse from '../../components/bootstrap/Collapse';
import { isEmail, isMessengerPasswordValid } from '../../lib/checker';
import showNotification from '../../components/extras/showNotification';
import { motion } from 'framer-motion';
import { commonValidationAttribute, useLog } from '../../lib/Util';
import moment from 'moment';
import useEmailExpirationTime from '../../hooks/useEmailExpirationTime';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import { auth } from '../../menu';
import { useNavigate } from 'react-router-dom';
import RocketChatService from '../../services/RocketChatService';
import md5 from 'md5';
import TermsAndPrivacyService from '../../services/TermsAndPrivacyService';
import Modal, { ModalBody, ModalFooter } from '../../components/bootstrap/Modal';
import LoadingSpinner from './component/LoadingSpinner';
import companyLogo from '../../assets/logo.png'

const NAME = 'name';
const EMAIL = 'email';
const IS_SUBMITTED_VERIFICATION_CODE = 'isSubmittedVerificationCode';
const VERIFICATION_CODE = 'verificationCode';
const IS_VERIFICATION_CODE_CONFIRMED = 'isVerificationCodeConfirmed';
const PASSWORD = 'password';
const CONFIRM_PASSWORD = 'confirmPassword';
const TERMS_OF_SERVICE = 'termsOfService';
const PRIVACY_POLICY = 'privacyPolicy';
const SIGNUP = 'SIGNUP';

const LOGIN_PATH = auth.login.path;

const SignUp = () => {
	const navigate = useNavigate();
	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const { isIntervalRunning, doClearInterval, getExpirationTime, renderRemainingTime } =
		useEmailExpirationTime();
	const [openModal, setOpenModal] = useState(false);
	const [termsAndPrivacyHTML, setTermsAndPrivacyHTML] = useState({
		terms: null,
		privacy: null,
	});
	const [selectedHTML, setSelectedHTML] = useState(TERMS_OF_SERVICE);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getTermsAndPrivacyHTML();
	}, []);

	const getTermsAndPrivacyHTML = async () => {
		const terms = await TermsAndPrivacyService.getTermsAndServiceHTML();
		const privacy = await TermsAndPrivacyService.getPrivacyPolicyHTML();
		setTermsAndPrivacyHTML({ terms: terms.data, privacy: privacy.data });
	};

	const formik = useFormik({
		initialValues: {
			[NAME]: '',
			[EMAIL]: '',
			[IS_SUBMITTED_VERIFICATION_CODE]: false,
			[VERIFICATION_CODE]: '',
			[IS_VERIFICATION_CODE_CONFIRMED]: false,
			[PASSWORD]: '',
			[CONFIRM_PASSWORD]: '',
			[TERMS_OF_SERVICE]: false,
			[PRIVACY_POLICY]: false,
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = '이메일을 입력해주세요.';
			} else if (!isEmail(values.email)) {
				errors.email = '이메일 형식이 올바르지 않습니다.';
			}
			if (!values.verificationCode) {
				errors.verificationCode = '인증코드를 입력해주세요.';
			}
			if (!values.name) {
				errors.name = '이름을 입력해주세요.';
			} else if (values.name.length <= 2) {
				errors.name = '이름은 2자리 이상 입력해주세요.';
			}
			if (!values.password) {
				errors.password = '비밀번호를 입력해주세요.';
			} else if (!isMessengerPasswordValid(values.password)) {
				errors.password = '비밀번호는 6자리 ~ 35자리 이내로 입력해주세요.';
			}
			if (!values.confirmPassword) {
				errors.confirmPassword = '비밀번호를 입력해주세요.';
			} else if (values.password !== values.confirmPassword) {
				errors.confirmPassword = '비밀번호가 일치하지 않습니다.';
			}
			if (!values.termsOfService) {
				errors.termsOfService = '회원가입을 완료하시려면 서비스 이용약관에 동의해주셔야 합니다.';
			}
			if (!values.privacyPolicy) {
				errors.privacyPolicy = '회원가입을 완료하시려면 개인정보 처리방침에 동의해주셔야 합니다.';
			}

			return errors;
		},
		onSubmit: async (values) => {
			setIsLoading(true);
			await RocketChatService.createUser({
				...values,
				password: md5(values.password),
				confirmPassword: md5(values.confirmPassword),
			})
				.then((res) => {
					if (res.data.success === true) {
						navigate(LOGIN_PATH);
						showNotification('회원가입 성공', '성공적으로 회원가입에 성공하였습니다.', 'success');
					} else if (res.data.success === false) {
						setIsLoading(false);
						showNotification(
							'회원가입 실패',
							res?.data?.message || '회원가입 인증에 실패하였습니다. 나중에 다시 시도해 주세요.',
							'warning',
						);
					}
				})
				.catch((error) => {
					setIsLoading(false);
					showNotification(
						'회원가입 실패',
						'회원가입 진행 도중에 문제가 발생하였습니다. 나중에 다시 시도해 주세요.',
						'danger',
					);
				});
		},
	});

	const handleVerificationCodeSubmit = async () => {
		if (!isEmail(formik.values.email)) {
			formik.validateForm();
			formik.setFieldTouched(EMAIL, true);
			return;
		}
		const serviceSuccess = () =>
			showNotification(
				'인증코드 전송',
				`${formik.values.email}로 회원가입 인증코드를 발송하였습니다.
				메일이 도착하는데 시간이 소요될 수 있습니다.`,
				'success',
			);
		const serviceFailed = () =>
			showNotification(
				'인증코드 전송 실패',
				`${formik.values.email}로 인증코드를 전송하는데 실패하였습니다.`,
				'danger',
			);

		await RocketChatService.sendVerificationCodeToEmail(formik.values.email, SIGNUP)
			.then((res) => {
				if (res.data.success === true) {
					const backendExpirationTime = res.data.expirationTime;
					formik.setFieldValue(IS_SUBMITTED_VERIFICATION_CODE, true);

					doClearInterval();
					getExpirationTime(backendExpirationTime);

					serviceSuccess();
				} else if (res.data.success === false) {
					showNotification(
						'이메일 중복',
						res.data.message || '이미 사용 중인 이메일입니다. 다른 이메일을 입력해주세요.',
						'warning',
					);
				}
			})
			.catch((err) => {
				serviceFailed();
			});
	};

	const handleVerificationCodeConfirmed = async () => {
		if (formik.errors.verificationCode) {
			formik.validateForm();
			formik.setFieldTouched(VERIFICATION_CODE, true);
			return;
		}
		await RocketChatService.confirmVerificationCode(
			formik.values.email,
			SIGNUP,
			formik.values.verificationCode,
		)
			.then((res) => {
				if (res.data.success === true) {
					setIsCollapseOpen(true);
					formik.setFieldValue(IS_VERIFICATION_CODE_CONFIRMED, true);

					// interval이 작동하고 있을 수 있기 때문에 초기화 시켜줌
					doClearInterval();
				} else if (res.data.success === false) {
					showNotification(
						'이메일 인증',
						res?.data?.message || '유효하지 않은 인증코드입니다. 다시 시도해 주세요.',
						'warning',
					);
				}
			})
			.catch((error) =>
				showNotification(
					'이메일 인증',
					'이메일 인증 도중에 문제가 발생하였습니다. 나중에 다시 시도해 주세요.',
					'danger',
				),
			);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		if ([EMAIL, VERIFICATION_CODE, NAME, PASSWORD, CONFIRM_PASSWORD].includes(name)) {
			formik.setFieldValue(name, value.trim());
		} else if ([TERMS_OF_SERVICE, PRIVACY_POLICY].includes(name)) {
			formik.setFieldValue(name, event.target.checked);
		} else {
			formik.setFieldValue(name, value);
		}
	};

	const checkMotionOn = (
		<motion.div
			initial={{ opacity: 0, scale: 0, rotate: 180 }}
			animate={{ opacity: 1, scale: 1, rotate: 0 }}
			transition={{ duration: 0.7 }}>
			<Icon icon='Check' color='success' />
		</motion.div>
	);

	return (
		<PageWrapper title={auth.signUp.text} className={'login-bg'}>
			<Page>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8'>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.5 }}>
							<Card className='card-box shadow' data-tour='login-page'>
								<CardHeader className='card-box-head flex-column mt-5'>
                  <img src={companyLogo} alt="logo" className='logo'  />
									<div className='text-center text-color-white mt-5'>회원가입을 위한 이메일 인증을 진행하세요.</div>
								</CardHeader>
								<CardBody className='row card-box-body'>
									<form onSubmit={formik.handleSubmit}>
										<FormGroup className='mb-3'>
											<Label className='d-flex align-items-center gap-1'>
												이메일 인증
												{!formik.errors.email &&
												!formik.errors.verificationCode &&
												formik.values.isSubmittedVerificationCode &&
												formik.values.isVerificationCodeConfirmed ? (
													checkMotionOn
												) : (
													<></>
												)}
											</Label>
											<div className='mb-2'>
												<InputGroup>													
													<Input
                            className="formstb-line text-color-white"
														id={EMAIL}
														type='email'
														placeholder='이메일 입력하세요.'
														value={formik.values.email}
														disabled={
															formik.values.isVerificationCodeConfirmed ||
															formik.values.isSubmittedVerificationCode
														}
														onChange={handleChange}
														//eslint-disable-next-line react/jsx-props-no-spreading
														{...commonValidationAttribute(formik, EMAIL)}
													/>
													<Button
														disabled={
															formik.values.isVerificationCodeConfirmed || formik.errors.email
														}
														color='dark'
														onClick={handleVerificationCodeSubmit}>
														{formik.values.isSubmittedVerificationCode
															? '인증코드 재전송'
															: '인증코드 전송'}
													</Button>
												</InputGroup>
											</div>
											<div>
												<InputGroup>													
													<Input
                            className="formstb-line text-color-white"
														id={VERIFICATION_CODE}
														type='text'
														placeholder='인증코드 입력'
														value={formik.values.verificationCode}
														disabled={
															!formik.values.isSubmittedVerificationCode ||
															formik.values.isVerificationCodeConfirmed
														}
														onChange={handleChange}
														//eslint-disable-next-line react/jsx-props-no-spreading
														{...commonValidationAttribute(formik, VERIFICATION_CODE)}
													/>
													<Button
														disabled={
															!formik.values.isSubmittedVerificationCode ||
															formik.values.isVerificationCodeConfirmed ||
															formik.errors.verificationCode ||
															!isIntervalRunning
														}
														color='dark'
														onClick={handleVerificationCodeConfirmed}>
														인증코드 확인
													</Button>
												</InputGroup>
												{renderRemainingTime(
													formik.values.isSubmittedVerificationCode &&
														!formik.values.isVerificationCodeConfirmed,
												)}
											</div>
										</FormGroup>

										<Collapse isOpen={isCollapseOpen}>
											<FormGroup className='mb-3'>
												<Label className='d-flex align-items-center gap-1'>
													계정 생성
													{formik.isValid ? checkMotionOn : <></>}
												</Label>
												<div className='mb-2'>
													<InputGroup>
														<InputGroupText className='bg-white'>
															<Icon icon='Person' size='lg' />
														</InputGroupText>
														<Input
															id={NAME}
															type='text'
															placeholder='이름 입력'
															value={formik.values.name}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, NAME)}
														/>
													</InputGroup>
												</div>
												<div className='mb-2'>
													<InputGroup>
														<InputGroupText className='bg-white'>
															<Icon icon='Lock' size='lg' />
														</InputGroupText>
														<Input
															id={PASSWORD}
															type='password'
															passwordVisibleIcon
															placeholder='비밀번호 입력'
															value={formik.values.password}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, PASSWORD)}
														/>
													</InputGroup>
												</div>
												<div>
													<InputGroup>
														<InputGroupText className='bg-white'>
															<Icon icon='Lock' size='lg' />
														</InputGroupText>
														<Input
															id={CONFIRM_PASSWORD}
															type='password'
															passwordVisibleIcon
															placeholder='비밀번호 확인'
															value={formik.values.confirmPassword}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, CONFIRM_PASSWORD)}
														/>
													</InputGroup>
												</div>
											</FormGroup>

											<FormGroup className='mb-3'>
												<Checks
													id={TERMS_OF_SERVICE}
													type='checkbox'
													label='서비스 이용약관을 확인하였으며, 이에 동의합니다.(필수)'
													checked={formik.values.termsOfService}
													onChange={(e) => {
														if (e.target.checked) {
															setSelectedHTML(TERMS_OF_SERVICE);
															setOpenModal(true);
														}
														handleChange(e);
													}}
													//eslint-disable-next-line react/jsx-props-no-spreading
													{...commonValidationAttribute(formik, TERMS_OF_SERVICE)}
												/>
												<Checks
													id={PRIVACY_POLICY}
													type='checkbox'
													label='개인정보취급방침을 확인하였으며, 이에 동의합니다.(필수)'
													checked={formik.values.privacyPolicy}
													onChange={(e) => {
														if (e.target.checked) {
															setSelectedHTML(PRIVACY_POLICY);
															setOpenModal(true);
														}
														handleChange(e);
													}}
													//eslint-disable-next-line react/jsx-props-no-spreading
													{...commonValidationAttribute(formik, PRIVACY_POLICY)}
												/>
											</FormGroup>

											<Button
												color='primary'
												className='col-12 py-3 d-flex justify-content-center align-items-center gap-1'
												type='submit'
												disabled={isLoading}
												isDisable={!formik.isValid && !!formik.submitCount}>
												<div>메신저 시작하기</div>
												{isLoading ? <LoadingSpinner /> : <></>}
											</Button>
										</Collapse>
									</form>
								</CardBody>
								<CardFooter className='card-box-foot pt-0'>
									<Button
										icon='ArrowBack'
                    className='text-color-white f-n'
										onClick={() => {
											navigate(LOGIN_PATH);
										}}>
										로그인 화면으로
									</Button>
								</CardFooter>
							</Card>
						</motion.div>
					</div>
				</div>

				<Modal isOpen={openModal} setIsOpen={setOpenModal} size={'xl'}>
					<ModalBody>
						<iframe
							width='100%'
							height={window.innerHeight - 200}
							srcDoc={
								selectedHTML === TERMS_OF_SERVICE
									? termsAndPrivacyHTML.terms
									: termsAndPrivacyHTML.privacy
							}></iframe>
					</ModalBody>
					<ModalFooter className='bg-info d-flex justify-content-center'>
						<Button
							className='text-white'
							size='lg'
							onClick={() => {
								setOpenModal(false);
							}}>
							닫기
						</Button>
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default SignUp;
