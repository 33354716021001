import moment from 'moment';
import React, { useEffect } from 'react';
import { Navigate, Navigate as navigate } from 'react-router-dom';
import { auth, userMenu } from '../menu';
import { getItem, removeItem, setItem } from './localstorage';

export function divisionArray(arr, n) {
	const len = arr.length;
	const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
	let tmp = [];
	for (var i = 0; i < cnt; i++) {
		tmp.push(arr.splice(0, n));
	}
	return tmp;
}

export const checkMoment = (input, format = 'YYYY-MM-DD') => {
	const formats = [
		'YYYY-MM-DD',
		'YYYYMMDD',
		'YYYY_MM_DD',
		'YYYY/MM/DD',
		'MM/DD/YYYY',
		'MM-DD-YYYY',
		'MM_DD_YYYY',
	]; // 이후 계속 추가
	for (let i = 0; i < formats.length; i++) {
		if (moment(input, formats[i]).isValid() === true)
			return moment(input, formats[i]).format(format);
	}
	return input;
};

export const convertType = (type) => {
	switch (type) {
		case 'FLVN01':
			return '공급사';
		case 'FLCU01':
			return '고객사(납품사)';
		default:
			return type;
	}
};

export function convertMarker(original, search) {
	const reg = RegExp(search, 'gi');
	return (
		<span
			dangerouslySetInnerHTML={{
				__html: original.replaceAll(
					reg,
					`<span style="background-color: yellow; font-weight: bold;">${search}</span>`,
				),
			}}></span>
	);
}

export function useLog(value, str) {
	return useEffect(() => {
		console.log(`${str} === `, value);
	}, [value, str]);
}

export const messengerLiterals = {
	MESSENGER_EMAIL: 'Messenger_Email',
	MESSENGER_USERID: 'Messenger_UserId',
	MESSENGER_TOKEN: 'Messenger_Token',
	MESSENGER_TOKEN_EXPIRES_DATE: 'Messenger_Token_Expires_date',
};

export const getLoginData = () => {
	const email = getItem(messengerLiterals.MESSENGER_EMAIL);
	const userId = getItem(messengerLiterals.MESSENGER_USERID);
	const token = getItem(messengerLiterals.MESSENGER_TOKEN);
	const tokenExpiresDate = getItem(messengerLiterals.MESSENGER_TOKEN_EXPIRES_DATE);
	return {
		email,
		userId,
		token,
		tokenExpiresDate,
	};
};

export const perfromLogin = (email, userId, token, tokenExpiresDate, navigate) => {
	setItem(messengerLiterals.MESSENGER_EMAIL, email);
	setItem(messengerLiterals.MESSENGER_USERID, userId);
	setItem(messengerLiterals.MESSENGER_TOKEN, token);
	setItem(messengerLiterals.MESSENGER_TOKEN_EXPIRES_DATE, tokenExpiresDate);
	if (navigate) {
		navigate(userMenu.dashboard.path, {state: userMenu.dashboard});
	}
};

export const performLogout = () => {
	removeItem(messengerLiterals.MESSENGER_USERID);
	removeItem(messengerLiterals.MESSENGER_TOKEN);
	removeItem(messengerLiterals.MESSENGER_TOKEN_EXPIRES_DATE);
	window.location = auth.login.path;
};

export const commonValidationAttribute = (formik, key) => {
	return {
		onBlur: formik.handleBlur,
		isValid: formik.isValid,
		isTouched: formik.touched[key],
		invalidFeedback: formik.errors[key],
	};
};
