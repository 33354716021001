import { Base64 } from 'js-base64';
import Cookies from 'universal-cookie';
import config from '../config';

export const setItem = (key, value) => {
	const cookies = new Cookies();
	// localStorage.setItem(Base64.encode(key), Base64.encode(value));
	// console.log(config.COOKIE_URL, key, value)
	// switch (key) {
	// 	case 'refresh-token':
	// 	case 'access-token':
	// 		cookies.set(Base64.encode(key), Base64.encode(value), {
	// 			path: '/',
	// 			domain: config.COOKIE_URL,
	// 			// secure: config.COOKIE_URL === 'localhost' ? false : true,
	// 			// httpOnly: true,
	// 			// sameSite: 'none',
	// 		});
	// 		break;
	// 	default:
			localStorage.setItem(Base64.encode(key), Base64.encode(value));
	// 		break;
	// }
};

export const getItem = (key) => {
	const cookies = new Cookies();
	// return localStorage.getItem(Base64.encode(key))
	// 	? Base64.decode(localStorage.getItem(Base64.encode(key)))
	// 	: null;
	// console.log("frontend1", key, cookies.get(Base64.encode(key)))
	// switch (key) {
	// 	case 'refresh-token':
	// 	case 'access-token':
	// 		return cookies.get(Base64.encode(key)) ? Base64.decode(cookies.get(Base64.encode(key))) : null;
	// 	default:
			return localStorage.getItem(Base64.encode(key)) ? Base64.decode(localStorage.getItem(Base64.encode(key))) : null;
	// }
};

export const removeItem = (key) => {
	const cookies = new Cookies();

	// switch (key) {
	// 	case 'refresh-token':
	// 	case 'access-token':
	// 		cookies.remove(Base64.encode(key), {
	// 			path: '/',
	// 			domain: config.COOKIE_URL,
	// 			// secure: config.COOKIE_URL === 'localhost' ? false : true,
	// 			// httpOnly: true,
	// 			// sameSite: 'none',
	// 		});
	// 		break;
	// 	default:
			localStorage.removeItem(Base64.encode(key));
	// 		break;
	// }
};
