import React, { useLayoutEffect, forwardRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ThemeContext from '../../contexts/themeContext';

const PageWrapper = forwardRef(({ title, description, className, children }, ref) => {
	const { directCount, favoriteCount, groupCount } = useContext(ThemeContext);

	useLayoutEffect(() => {
		document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
			process.env.REACT_APP_SITE_NAME
		} ${
			directCount + favoriteCount + groupCount > 0
				? ` ::: 새메시지 (${directCount + favoriteCount + groupCount})`
				: ''
		}`;
		document
			.querySelector('meta[name="description"]')
			.setAttribute('content', description || process.env.REACT_APP_META_DESC);
	});

	const eventShake = () => {
		// console.log('eventShake');
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
		// console.log(dualScreenLeft, dualScreenTop)

		let count = 0;
		const interval = setInterval(() => {
			// console.log('>>>', window);
			if (count > 5) clearInterval(interval);
			count++;
			if (count % 2 === 0) window.moveTo(dualScreenLeft + 10, dualScreenTop);
			else window.moveTo(dualScreenLeft - 10, dualScreenTop);
		}, 100);
	};

	useEffect(() => {
		if (directCount + favoriteCount + groupCount > 0) eventShake();
	}, [directCount, favoriteCount, groupCount]);

	return (
		<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
			{children}
		</div>
	);
});
PageWrapper.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	title: null,
	description: null,
	className: null,
};

export default PageWrapper;
