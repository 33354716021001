import React, { createContext, useLayoutEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import useDeviceScreen from '../hooks/useDeviceScreen';

const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
	const deviceScreen = useDeviceScreen();
	const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;

	const [darkModeStatus, setDarkModeStatus] = useState(
		process.env.REACT_APP_DARK_MODE === 'true',
	);

	const [fullScreenStatus, setFullScreenStatus] = useState(false);
	const [moreMenuRef, setMoreMenuRef] = useState(null);

	const [leftMenuStatus, setLeftMenuStatus] = useState(false);
	const [rightMenuStatus, setRightMenuStatus] = useState(false);
	const [asideStatus, setAsideStatus] = useState(
		deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE,
	);

	const [rightPanel, setRightPanel] = useState(false);
	const [chatMode, setChatMode] = useState(true);
	const [tempUploadFiles, setTempUploadFiles] = useState([]);
	const [directCount, setDirectCount] = useState(0);
	const [favoriteCount, setFavoriteCount] = useState(0);
	const [groupCount, setGroupCount] = useState(0);
	const [chatScrollRef, setChatScrollRef] = useState(null);

	useLayoutEffect(() => {
		if (deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) {
			setAsideStatus(true);
			setLeftMenuStatus(false);
			setRightMenuStatus(false);
		}
		return () => {
			setAsideStatus(false);
		};
	}, [deviceScreen.width]);

	const values = useMemo(
		() => ({
			mobileDesign,
			darkModeStatus,
			setDarkModeStatus,
			fullScreenStatus,
			setFullScreenStatus,
			asideStatus,
			setAsideStatus,
			leftMenuStatus,
			setLeftMenuStatus,
			rightMenuStatus,
			setRightMenuStatus,
			rightPanel,
			setRightPanel,
			chatMode,
			setChatMode,
			moreMenuRef,
			setMoreMenuRef,
			tempUploadFiles,
			setTempUploadFiles,
			directCount,
			setDirectCount,
			favoriteCount, 
			setFavoriteCount,
			groupCount, 
			setGroupCount,
			chatScrollRef,
			setChatScrollRef,
		}),
		[
			asideStatus,
			darkModeStatus,
			fullScreenStatus,
			leftMenuStatus,
			mobileDesign,
			rightMenuStatus,
			rightPanel,
			chatMode,
			moreMenuRef,
			tempUploadFiles,
			directCount,
			favoriteCount,
			groupCount,
			chatScrollRef,
		],
	);

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};
ThemeContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeContext;
