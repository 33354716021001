import React from 'react';
import { auth } from '../menu';
import SearchHeader from '../page/Headers/SearchHeader';

const headers = [
	// { path: '/', element: null, exact: true },
	{ path: auth.login.path, element: null, exact: true },
	{ path: auth.signUp.path, element: null, exact: true },
	{ path: auth.findPassword.path, element: null, exact: true },
	{ path: auth.page404.path, element: null, exact: true },
	{
		path: `*`,
		element: <SearchHeader />,
	},
];

export default headers;
