import { types } from 'mobx-state-tree';

export const Chat = types
	.model('Chat', {
		userId : types.maybe(types.string),
		authToken : types.maybe(types.string),
		roomType : types.maybe(types.string),
	})
	.views((self) => ({
		get getUserId() {
			return self.userId;
		},
		get getAuthToken() {
			return self.authToken;
		},
		get getRoomType() {
			return self.roomType;
		},
	}))
	.actions((self) => ({
		async setUserId(userId) {
			self.userId = userId;
		},
		async setAuthToken(authToken) {
			self.authToken = authToken;
		},
		async setRoomType(roomType) {
			self.roomType = roomType;
		},
		async logout() {
			self.userId = '';
			self.authToken = '';
			self.roomType = '';
		},
	}));

export default Chat;
