import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs';
import config from '../../config';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';
import showNotification from '../../components/extras/showNotification';
import md5 from 'md5';
import { getLoginData } from '../../lib/Util';

console.log(config.WEBSOCKET);
const realTimeAPI = new RealTimeAPI(config.WEBSOCKET);
realTimeAPI.connectToServer();
realTimeAPI.keepAlive().subscribe();
const loginData = getLoginData();

const merge = (a, b, p1, p2) =>
	a.filter((aa) => !b.find((bb) => aa[p1] === bb[p2 || p1])).concat(b);
Array.prototype.blockit = function () {
	if (!Array.isArray(this)) return this;
	this.map((c, i) => {
		try {
			const msg = JSON.parse(c.msg);
			c.isBlockKit = typeof msg === 'object';
			if (!msg.blocks) c.isBlockKit = false;
		} catch (e) {
			c.isBlockKit = false;
		}
		return c;
	});
	return this;
};

const post = (url, data, chatUser) => {
	return axios({
		url: config.CHAT_URL + '/api/v1/' + url,
		method: 'POST',
		headers: {
			'X-User-Id': chatUser.userId,
			'X-Auth-Token': chatUser.authToken,
		},
		data: data,
	});
};

const get = (url, chatUser) => {
	return axios({
		url: config.CHAT_URL + '/api/v1/' + url,
		method: 'GET',
		headers: {
			'X-User-Id': chatUser.userId,
			'X-Auth-Token': chatUser.authToken,
		},
	});
};

/**
 * Call Method
 */
const callMethod = (method, params, callback, complete) => {
	realTimeAPI.callMethod(method, ...params).subscribe(
		(data) => {
			if (callback) callback(data);
		},
		(err) => console.log(err),
		() => {
			console.log(method + ' complete!');
			if (complete) complete();
		},
	);
};

/**
 * Subscription
 */
const subscription = (method, params) => {
	return realTimeAPI.getSubscription(method, ...params);
};

/**
 * 사용자 정보
 * @param {OBJECT} userData
 * @param {STRING} userData.userId
 * @param {STRING} userData.authToken
 */
export const getMyInfo = (userData, callback) => {
	return get('me', userData);
};

/**
 *	사용자 환경 설정
 * @param {OBJECT} userData
 * @param {STRING} userData.userId
 * @param {STRING} userData.authToken
 */
export const getUserPreferences = (userData) => {
	return get('users.getPreferences', userData);
};

export const setPreferences = (data, userData) => {
	return post('users.setPreferences', { data }, userData);
};

/**
 * 사용자 정보(프로필) 수정
 * @param {OBJECT} data
 * @param {STRING} data.realname
 * @param {STRING} data.bio
 * @param {STRING} data.newPassword
 */
export const updateOwnBasicInfo = (data, callBack, complete) => {
	return callMethod('saveUserProfile', [data], (data) => callBack(data), null);
};

/**
 * 현재 계정을 제외한 다른 위치의 계정 로그아웃
 */
export const logoutOtherClients = () => {
	return post('users.logoutOtherClients', null, {
		userId: loginData.userId,
		authToken: loginData.token,
	});
};

/**
 * 메시지 수신 구독
 * @param {OBJECT} room
 */
export const subscriptionMessage = (room) => {
	return subscription('stream-room-messages', [room.rid, false]);
};

/**
 * 로그인 사용자 행동 감시
 * @param {OBJECT} room
 * @param {STRING} event
 * Events available:
 * message
 * otr (Off the Record Message)
 * webrtc
 * notification
 * rooms-changed
 * subscriptions-changed
 */
export const notifyUser = (userId, event) => {
	return subscription('stream-notify-user', [userId + '/' + event, false]);
};

/**
 * 채팅방 사용자 행동 감시
 * @param {OBJECT} room
 * @param {STRING} event
 * Events available:
 * webrtc
 */
export const notifyRoomUsers = (userId, event) => {
	return subscription('stream-notify-room-users', [userId + '/' + event, false]);
};

/**
 * 채팅방 변경 감시
 * @param {OBJECT} room
 * @param {STRING} event
 * Events available:
 * webrtc
 */
export const notifyRoom = (room, event) => {
	return subscription('stream-notify-room', [room.rid + '/' + event, false]);
};

/**
 * 로그 변경 감시
 * @param {OBJECT} room
 * @param {STRING} event
 * Events available:
 * Users:NameChanged
 * Users:Deleted
 * updateAvatar
 * updateEmojiCustom
 * deleteEmojiCustom
 * roles-change
 * user-status
 */
export const notifyLogged = (event) => {
	return subscription('stream-notify-logged', [event, false]);
};

/**
 * 채팅방 변경 감시
 * @param {OBJECT} room
 * @param {STRING} event
 * Events available:
 * roles-change
 * updateEmojiCustom
 * deleteEmojiCustom
 * updateAvatar
 * public-settings-changed
 * permissions-changed
 */
export const notifyAll = (event) => {
	return subscription('stream-notify-all', [event, false]);
};

/**
 * 채팅 로그인
 * @param {OBJECT} userData
 * @param {FUNCTION} callback
 */
export const chatLogin = (userData, callback) => {
	realTimeAPI.login(userData.email, md5(userData.password)).subscribe(
		(data) => {
			console.log('data ==== ', data);
			if (data.error) {
				showNotification(
					'로그인 실패',
					'로그인에 실패했습니다. 올바른 이메일과 비밀번호를 입력해주세요.',
					'danger',
				);
				callback(null);
			}
			if (!data.error) {
				callback(data);
			}
		},
		(err) => showNotification('로그인 실패', '로그인에 실패하였습니다.', 'danger'),
	);
};

/**
 *	채팅 로그인 인증 with token
 *	@param {STRING} TOKEN
 *	@param {FUNCTION} callback
 */
export const chatLoginWithAuthToken = (token, callback) => {
	realTimeAPI.loginWithAuthToken(token).subscribe((data) => {
		if (data.error) {
			showNotification(
				'로그인 실패',
				'로그인에 실패했습니다. 올바른 이메일과 비밀번호를 입력해주세요.',
				'danger',
			);
			callback(null);
		}
		if (!data.error) {
			callback(data);
		}
	});
};

/**
 * 채팅방 새로고침
 * @param {FUNCTION} callback
 * @param {FUNCTION} complete
 */
export const getSsubscriptions = (callback, complete) => {
	callMethod(
		'subscriptions/get',
		[{ $date: moment().unix() }],
		(data) => data.result?.update ? callback(data.result?.update) : null,
		complete,
	);
};

/**
 * 채팅방 새로고침
 * @param {FUNCTION} callback
 * @param {FUNCTION} complete
 */
export const getRooms = (callback, complete) => {
	callMethod(
		'rooms/get',
		[{ $date: moment().unix() }],
		(data) => callback(data.result.update),
		complete,
	);
};

/**
 * 채팅방 나가기
 * @param {FUNCTION} callback
 * @param {FUNCTION} complete
 */
export const leaveRoom = (currentRoom, callback, complete) => {
	callMethod('leaveRoom', [currentRoom.rid], (data) => callback(data), complete);
};

/**
 * 채팅방 숨기기 1:1 전용
 * @param {FUNCTION} callback
 * @param {FUNCTION} complete
 */
export const hideRoom = (currentRoom, callback, complete) => {
	callMethod('hideRoom', [currentRoom.rid], (data) => callback(data), complete);
};

/**
 * 채팅방 지난대화 불러오기
 * @param {FUNCTION} callback
 * @param {FUNCTION} complete
 */
export const loadHistory = (currentRoom, callback, ts = null, count = 50) => {
	callMethod('loadHistory', [currentRoom.rid, ts, count, { $date: moment().unix() }], (data) => {
		if (!data.error) {
			callback(data);
			// let unreadNotLoaded = data.result.unreadNotLoaded
			// setChatMessage(data.result.messages.reverse().blockit())
		}
	});
};

export const getReadReceipts = (messageId, callback) => {
	callMethod('getReadReceipts', [{ messageId: messageId }], callback);
};

export const getChatUsers = async (chatUser) => {
	const users = await get('users.list', chatUser);
	return users.data.users.map((u) => {
		u.value = u.username;
		u.label = u.name;
		return u;
	});
};

export const getRoomRoles = async (currentRoom, callback) => {
	// console.log(currentRoom)
	callMethod('getRoomRoles', [currentRoom.rid], (data) => {
		// console.log(data)
		const tempRoomRoles = {};
		try {
			for (let r = 0; r < data.result.length; r++) {
				tempRoomRoles[data.result[r].u._id] = data.result[r];
			}
			callback(tempRoomRoles);
		} catch (e) {
		}
	});
};

export const getRoomFiles = async (room, chatUser) => {
	const roomType = room.t === 'd' ? 'im' : 'groups';
	const files = await get(roomType + '.files?count=500&offset=0&roomId=' + room.rid, chatUser);

	// console.log(users.data.members)
	return files.data.files;
};

export const kickUser = async (roomId, userId, chatUser) => {
	const result = await post('groups.kick', { roomId, userId }, chatUser);
	return result;
};

export const getRoomUsers = async (room, chatUser, roomRoles) => {
	const roomType = room.t === 'd' ? 'im' : 'groups';
	const users = await get(roomType + '.members?count=500&offset=0&roomId=' + room.rid, chatUser);

	// console.log(users.data.members)
	const members = users.data.members.map((m) => Object.assign(m, roomRoles[m._id]));
	return members;
};

export const isExistRoomName = async (roomName, chatUser) => {
	const isExistRoomName = await get('rooms.nameExists?roomName=' + roomName, chatUser);
	return isExistRoomName.data.exists;
};

export const modifyRoomName = async (data, chatUser) => {
	const result = await post('rooms.saveRoomSettings', data, chatUser);
	return result.data;
};

export const removeMember = async (rid, callback) => {
	callMethod('eraseRoom', [rid], callback);
};

export const createChatRoom = async (roomName, usernames, callback) => {
	callMethod('createPrivateGroup', [roomName, usernames], callback);
};

export const typing = async (room, userId, isTyping) => {
	callMethod('stream-notify-room', [room.rid + '/typing', userId, isTyping]);
};

export const setStatus = async (status, statusMessage, chatUser) => {
	const data = {};
	if (status) {
		data['status'] = status;
	}
	if (statusMessage !== null) {
		data['message'] = statusMessage;
	}

	const result = await post('users.setStatus', { ...data }, chatUser);
	return result.data;
};

export const subscriptionRead = async (data, chatUser) => {
	const result = await post('subscriptions.read', { rid: data.rid }, chatUser);
	return result.data;
};

export const resetAvatar = async (userData) => {
	const result = await post('users.resetAvatar', { username: userData.email }, userData);
	return result;
};

export const setAvatar = async (image, chatUser) => {
	const formData = new FormData();
	formData.append('image', image);

	const result = await post('users.setAvatar', formData, chatUser);
	return result;
};

/**
 * 1:1 채팅 시작하기
 * @param {STRING} username
 * @param {FUNCTION} callback
 */
export const createDirectMessage = async (username, callback) => {
	callMethod('createDirectMessage', [username], callback);
};

/**
 * 메시지 전송
 * @param {STRING} _id
 * @param {STRING} rid
 * @param {STRING} msg
 * @param {FUNCTION} callback
 */
export const sendMessage = async (_id, rid, msg, callback) => {
	callMethod('sendMessage', [{ _id, rid, msg }], (data) => {
		if (callback) callback(data);
	});
};

/**
 * 메시지 수정
 * @param {STRING} _id
 * @param {STRING} rid
 * @param {STRING} msg
 * @param {FUNCTION} callback
 */
export const updateMessage = async (_id, rid, msg, callback) => {
	console.log(msg)
	callMethod('updateMessage', [{ _id, rid, msg }], (data) => {
		if (callback) callback(data);
	});
};

/**
 * 채팅방 즐겨찾기 설정/해제
 * @param {FUNCTION} callback
 * @param {FUNCTION} complete
 */
export const toggleFavorite = async (currentRoom) => {
	console.log(currentRoom);
	callMethod('toggleFavorite', [currentRoom.rid, !!currentRoom.f], (data) => console.log(data));
};

export const joinUsers = async (currentRoom, joinUsers, chatUser) => {
	const join = await post(
		'method.call/addUsersToRoom',
		{
			message:
				'{"msg":"method","id":"163","method":"addUsersToRoom","params":[{"rid":"' +
				currentRoom.rid +
				'","users":["' +
				joinUsers.join('","') +
				'"]}]}',
		},
		chatUser,
	);
	return join;
};
