import { APIClient } from '../lib/apiHelper';

const { get, post, put, remove } = new APIClient();

export default {
	checkEmailDuplicate: (email) => get(`/checkEmailDuplicate?email=${email}`),
	sendVerificationCodeToEmail: (email, verificationType) =>
		get(`/sendVerificationCodeToEmail?email=${email}&verificationType=${verificationType}`),
	confirmVerificationCode: (email, verificationType, verificationCode) =>
		post('/confirmVerificationCode', { email, verificationType, verificationCode }),
	createUser: (userData) => post('/createUser', userData),
	deleteUser: (userId, email) => post('/deleteUser', { userId, email }),
	changePassword: (userData) => post('/changePassword', userData),
};
