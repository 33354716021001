import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const END_TIME = 'endTime';

const useEmailExpirationTime = () => {
	const [expirationTime, setExpirationTime] = useState(null);
	const intervalRef = useRef(null);

	const getExpirationTime = useCallback((backendExpirationTime) => {
		intervalRef.current = setInterval(() => {
			const backendExpTime = moment(backendExpirationTime).format('YYYY-MM-DD h:mm:ss');
			const currentTime = moment().format('YYYY-MM-DD h:mm:ss');
			const diff = moment(backendExpTime).diff(currentTime);

			if (diff < 0) {
				clearInterval(intervalRef.current);
				setExpirationTime(END_TIME);
			} else {
				setExpirationTime(moment(diff).format('mm:ss'));
			}
		}, 1000);
	}, []);

	const renderRemainingTime = (isValid) => {
		if (isValid && intervalRef.current && expirationTime !== null && expirationTime === END_TIME) {
			return <div>인증 시간이 만료되었습니다.</div>;
		}
		if (isValid && intervalRef.current && expirationTime !== null && expirationTime !== END_TIME) {
			return <div>{`남은 인증시간 : ${expirationTime}`}</div>;
		}
		return <></>;
	};

	const doClearInterval = useCallback(() => {
		intervalRef.current && clearInterval(intervalRef.current);
	}, []);

	const isIntervalRunning = !(expirationTime === null || expirationTime === END_TIME);

	useEffect(() => {
		return () => {
			doClearInterval();
		};
	}, [doClearInterval]);

	return {
		isIntervalRunning,
		getExpirationTime,
		renderRemainingTime,
		doClearInterval,
	};
};

export default useEmailExpirationTime;
