import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import { Provider, rootStore } from './models';

Number.prototype.format = function () {
if (this === 0) return 0
let reg = /(^[+-]?\d+)(\d{3})/
let n = (this + '')
while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2')
return n
}
  
String.prototype.format = function () {
let num = parseFloat(this)
if (isNaN(num)) return '0' 
return num.format()
}
  
ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<Provider value={rootStore}>
					<App />
				</Provider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
