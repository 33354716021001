import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation from '../Navigation/Navigation';
import { adminMenu, auth, userMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import { observer } from 'mobx-react';
import 'remixicon/fonts/remixicon.css';
import { useMst } from '../../models';
import { useNavigate } from 'react-router-dom';
import { performLogout } from '../../lib/Util';

const Aside = observer(() => {
	const { asideStatus, setAsideStatus, setMoreMenuRef } = useContext(ThemeContext);
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);
	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const [isNotiOffCanvasOpen, setIsNotiOffCanvasOpen] = useState(false);
	const { user } = useMst();

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={userMenu} id='user_menu' />
					{/* <Navigation menu={adminMenu} id='admin_menu' /> */}

					<ol className='navigation navi-logout'>
						<li
							className='navigation-item'
							onClick={async () => {
								window.open('https://chat.teamply.co.kr/api/v1/oauth/authorize?client_id=43G4KIN5TPJOT2GTXM5MJLQKZHACA907&redirect_uri=https://korens.teamply.co.kr/callback&response_type=code&state=test', 'teamply')
							}}>
							<div aria-current='page' className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<i className='ri-folders-line me-2'></i>
									<span className='navigation-text'>Work Manager</span>
								</span>
							</div>
						</li>
						<li
							className='navigation-item'
							onClick={async () => {
								user.logout();
							}}>
							<div aria-current='page' className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<i className='ri-logout-box-r-line me-2'></i>
									<span className='navigation-text'>로그아웃</span>
								</span>
							</div>
						</li>
					</ol>
				</div>
				<div className='aside-foot'></div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}

			<OffCanvas isOpen={isNotiOffCanvasOpen} setOpen={setIsNotiOffCanvasOpen}>
				<OffCanvasHeader setOpen={setIsNotiOffCanvasOpen}>
					<OffCanvasTitle id={'notiTitle'}>알림</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						알림 내역이 없습니다.
					</div>
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
});

export default Aside;
