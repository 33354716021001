import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMst } from '../../models';
import { useFormik } from 'formik';
import { chatLogin, getMyInfo, setStatus } from '../chat/Service';
import Logo from '../../components/Logo';
import Card, { CardBody, CardFooter, CardHeader } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Button from '../../components/bootstrap/Button';
import Checks from '../../components/bootstrap/forms/Checks';
import Input from '../../components/bootstrap/forms/Input';
import Icon from '../../components/icon/Icon';
import { isEmail } from '../../lib/checker';
import showNotification from '../../components/extras/showNotification';
import { motion } from 'framer-motion';
import moment from 'moment';
import {
	commonValidationAttribute,
	getLoginData,
	messengerLiterals,
	performLogout,
	perfromLogin,
} from '../../lib/Util';
import { auth, userMenu } from '../../menu';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import companyLogo from '../../assets/logo-box.svg'
import { getItem } from '../../lib/localstorage';
import AuthService from '../../services/AuthService';

const EMAIL = 'email';
const PASSWORD = 'password';
const IS_SAVE_EMAIL = 'isSaveEmail';

const SIGN_UP_PATH = auth.signUp.path;
const FIND_PASSWORD_PATH = auth.findPassword.path;

const Login = () => {
	const navigate = useNavigate();
	const { user, chat } = useMst();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get('redirect_uri')) {
			const isAuthPage = [auth.login.path, auth.signUp.path, auth.findPassword.path].includes(
				location.pathname,
			);
			const loginUserData = getLoginData();

			if (
				loginUserData.email &&
				loginUserData.userId &&
				loginUserData.token &&
				loginUserData.tokenExpiresDate
			) {
				const isTokenExpired = moment().isBefore(moment(new Date(loginUserData.tokenExpiresDate)));
				if (isTokenExpired) {
					user.logout();
					return;
				}
				if (!isTokenExpired) {
					AuthService.loginSuccess({
						authorizationCode: searchParams.get('code'),
						clientId: searchParams.get('client_id'),
						userId: loginUserData.email,
						redirectUri: searchParams.get('redirect_uri'),
						idToken: loginUserData.userId
					}).then(() => {
						user.authMe(
							loginUserData.email,
							loginUserData.userId,
							loginUserData.token,
							loginUserData.tokenExpiresDate,
						);
	
						location.href = searchParams.get('redirect_uri') + '?code=' + searchParams.get('code')
					})
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const formik = useFormik({
		initialValues: {
			[EMAIL]: getLoginData()?.email || '',
			[PASSWORD]: '',
			[IS_SAVE_EMAIL]: !!getLoginData()?.email || false,
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = '이메일을 입력해주세요.';
			} else if (!isEmail(values.email)) {
				errors.email = '이메일 형식이 올바르지 않습니다.';
			}
			if (!values.password) {
				errors.password = '비밀번호를 입력해주세요.';
			}

			return errors;
		},
		onSubmit: async (values) => {
			chatLogin({ email: values.email, password: values.password }, async (data) => {
				if (data.result && data.result.token && data.result.tokenExpires.$date && data.result.id) {
					localStorage.setItem(IS_SAVE_EMAIL, values.isSaveEmail);

					const response = await AuthService.loginSuccess({
						authorizationCode: searchParams?.get('code') || null,
						clientId: searchParams?.get('client_id') || null,
						redirectUri: searchParams?.get('redirect_uri') || null,
						userId: values.email,
						idToken: data.result.id,
					})

					user.authMe(
						values.email,
						data.result.id,
						data.result.token,
						data.result.tokenExpires.$date,
						searchParams.get('code') ? null : navigate,
					);

					if (searchParams.get('redirect_uri')) {
						location.href = searchParams.get('redirect_uri') + '?code=' + searchParams.get('code')
					}
				}
			});
		},
	});

	const handleChange = (event) => {
		const { name, value } = event.target;

		if ([EMAIL, PASSWORD].includes(name)) {
			formik.setFieldValue(name, value.trim());
		} else if ([IS_SAVE_EMAIL].includes(name)) {
			formik.setFieldValue(name, event.target.checked, false);
		} else {
			formik.setFieldValue(name, value);
		}
	};

	return (
		<PageWrapper title={auth.login.text} className={'login-bg'}>
			<Page className='mini-size'>
				<div className='row h-100 align-items-center'>
					<div className='col-xl-5 col-lg-6 col-md-6'>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.2 }}>
							<Card className='shadow card-box login-box' data-tour='login-page'>
								<CardHeader className='card-box-head flex-column align-items-center mt-5'>
									<img src={companyLogo} alt='logo' className='logo' />
								</CardHeader>
								<CardBody className='row card-box-body mt-5'>
									<div className='col-12'>
										<form onSubmit={formik.handleSubmit}>
											<FormGroup className='mb-2'>
												<InputGroup>
													<Input
														className='formstb-line'
														id={EMAIL}
														/* 임시로 email -> text로 해놓음, 이메일이 아닌 아이디로 로그인 가능하게 하기 위해서*/
														type='text'
														autoComplete='email'
														placeholder='이메일 입력'
														value={formik.values.email}
														onChange={handleChange}
														//eslint-disable-next-line react/jsx-props-no-spreading
														{...commonValidationAttribute(formik, EMAIL)}
													/>
												</InputGroup>
											</FormGroup>

											<FormGroup className='mb-2'>
												<InputGroup>
													<Input
														className='formstb-line'
														id={PASSWORD}
														type='password'
														// passwordVisibleIcon
														placeholder='비밀번호 입력'
														value={formik.values.password}
														onChange={handleChange}
														//eslint-disable-next-line react/jsx-props-no-spreading
														{...commonValidationAttribute(formik, PASSWORD)}
													/>
												</InputGroup>
											</FormGroup>

											<div className='mb-3 d-flex'>
												<Checks
													id={IS_SAVE_EMAIL}
													type='checkbox'
													className='cursor-pointer'													
													checked={formik.values.isSaveEmail}
													onChange={handleChange}
												/>
                        <label className='cursor-pointer' htmlFor={IS_SAVE_EMAIL}>이메일저장</label>
											</div>

											<Button
												color='primary'
												className='col-12 py-3 mt-5'
												type='submit'
												isDisable={!formik.isValid}>
												로그인
											</Button>
										</form>
									</div>
								</CardBody>
								<CardFooter className='card-box-foot pt-0 '>
									{/* <Button
										icon='Person'
                    className='f-n'
										onClick={() => {
											navigate(SIGN_UP_PATH);
										}}>
										회원가입
									</Button> */}
									<Button
										icon='LockOpen'
                    className='f-n'
										onClick={() => {
											navigate(FIND_PASSWORD_PATH);
										}}>
										비밀번호 찾기
									</Button>
								</CardFooter>
							</Card>
						</motion.div>
					</div>
          <div  className='col-xl-7 col-lg-6 col-md-6'>
            <div className='login-box-text'>
              <div className='stb-01'>밸류체인 디지털화 선도 R&D 신규사업</div>
              <div className='stb-02'>정형 비정형 데이터 기반 협업 및<br/>AI 서비스 플랫폼 개발</div>
              <div className='stb-03'>전기차 파워트레인 제조 밸류체친의 탄력성 확보</div>
            </div>            
          </div>
				</div>
        
			</Page>
		</PageWrapper>
	);
};

export default Login;
