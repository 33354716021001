import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import {
	Route,
	Routes,
	useLocation,
	useNavigate,
	useSearchParams,
	Navigate,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { auth, userMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import { useMst } from '../models';
import { removeItem } from '../lib/localstorage';
import { observer } from 'mobx-react';
import {
	chatLogin,
	setStatus,
	getSsubscriptions,
	getRooms,
	notifyUser,
	chatLoginWithAuthToken,
} from '../page/chat/Service';
import { getLoginData, performLogout, perfromLogin } from '../lib/Util';
import moment from 'moment';
import AuthService from '../services/AuthService';

const App = observer(() => {
	const location = useLocation();
	const navigate = useNavigate();
	const { user, chat } = useMst();
	const [rooms, setRooms] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus, setDirectCount, setFavoriteCount, setGroupCount } =
		useContext(ThemeContext);

	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	useEffect(() => {
		const isAuthPage = [auth.login.path, auth.signUp.path, auth.findPassword.path].includes(
			location.pathname,
		);
		const loginUserData = getLoginData();

		if (
			loginUserData.email &&
			loginUserData.userId &&
			loginUserData.token &&
			loginUserData.tokenExpiresDate
		) {
			const isTokenExpired = moment().isBefore(moment(new Date(loginUserData.tokenExpiresDate)));
			if (isTokenExpired) {
				user.logout();
				return;
			}
			if (!isTokenExpired) {
				if (isAuthPage) {
					user.authMe(
						loginUserData.email,
						loginUserData.userId,
						loginUserData.token,
						loginUserData.tokenExpiresDate,
						searchParams.get('code') ? null : navigate,
					);
					if (searchParams.get('redirect_uri')) {
						console.log('!!!!!!!!!!!');
						AuthService.loginSuccess({
							authorizationCode: searchParams.get('code'),
							clientId: searchParams.get('client_id'),
							userId: values.email,
							redirectUri: searchParams.get('redirect_uri'),
							idToken: loginUserData.userId,
						}).then(() => {
							location.href =
								searchParams.get('redirect_uri') + '?code=' + searchParams.get('code');
						});
					}
				}
				chatLoginWithAuthToken(loginUserData.token, async (data) => {
					if (!data) {
						user.logout();
						return;
					}
					if (
						data &&
						data.result &&
						data.result.id &&
						data.result.token &&
						data.result.tokenExpires.$date
					) {
						user.authMe(
							loginUserData.email,
							loginUserData.userId,
							loginUserData.token,
							loginUserData.tokenExpiresDate,
							null,
						);
					}
				});
			}
		} else {
			if (!isAuthPage) {
				navigate(auth.login.path);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const readCount = (rooms, type) => {
			let count = 0;
			if (type === 'f') {
				[...rooms]?.filter((v) => v.f === true)?.map((v) => (count += v?.unread || 0));
			} else {
				[...rooms]?.filter((v) => v.t === type)?.map((v) => (count += v?.unread || 0));
			}
			return count;
		};

		if (rooms.length > 0) {
			const dCount = readCount(rooms, 'd');
			setDirectCount(dCount);
			const fCount = readCount(rooms, 'f');
			setFavoriteCount(fCount);
			const pCount = readCount(rooms, 'p');
			setGroupCount(pCount);
			console.log('COUNT', dCount, fCount, pCount);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rooms]);

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [auth.login.path, auth.signUp.path, auth.findPassword.path];

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<div
					ref={ref}
					className='app'
					style={{
						backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
						zIndex: fullScreenStatus && 1,
						overflow: fullScreenStatus && 'scroll',
					}}>
					<Routes>
						{withOutAsidePages.map((path) => (
							<Route key={path} path={path} />
						))}
						<Route
							path='/'
							element={
								<Navigate
									replace
									to='/links/dash'
									state={{ url: 'https://dx.idrenvision.com:8071' }}
								/>
							}
						/>
						<Route
							path='/home'
							element={
								<Navigate
									replace
									to='/links/dash'
									state={{ url: 'https://dx.idrenvision.com:8071' }}
								/>
							}
						/>
						<Route path='*' element={<Aside />} />
					</Routes>
					<Wrapper />
				</div>
				<Portal id='portal-notification'>
					<ReactNotifications />
				</Portal>
			</ToastProvider>
		</ThemeProvider>
	);
});

export default App;
