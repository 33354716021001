export function isEmail(asValue) {
	const regExp =
		/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
	return regExp.test(asValue);
}

export function isMessengerPasswordValid(pw) {
	if (pw.length < 6 || pw.length > 35) {
		// 메신저 비밀번호는 회원가입 할 때 md5함수로 변경되어지는데, 변경된 비밀번호는 영문과 숫자로만 이루어져 있음.
		// 비밀번호는 6자리 ~ 35자리 이내로 입력해주세요.
		return false;
	}
	return true
}

export function isPassword(pw) {
	// let regExp = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,}$/
	// return regExp.test(asValue)
	const num = pw.search(/[0-9]/g);
	const eng = pw.search(/[a-zA-Z]/gi);
	const spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

	if (pw.length < 6 || pw.length > 16) {
		// alert("6자리 ~ 16자리 이내로 입력해주세요.");
		return false;
	}
	if (pw.search(/\s/) != -1) {
		// alert("비밀번호는 공백 없이 입력해주세요.");
		return false;
	}
	if (num < 0 || eng < 0 || spe < 0) {
		// alert("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
		return false;
	}
	// console.log("통과");
	return true;
}

export function isTel(asValue) {
	const regExp = /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/g;
	return regExp.test(asValue);
}

export function isPhone(asValue) {
	const regExp = /^01([0|1|6|7|8|9]?)?([0-9]{3,4})?([0-9]{4})$/g;
	return regExp.test(asValue);
}

export function isIncludeEmptyString(asValue) {
	const regExp = /[\s]/;
	return regExp.test(asValue);
}

export function isUserId(asValue) {
	const regExp = /^[a-zA-Z0-9@]{4,20}$/;
	return regExp.test(asValue);
}

export const haveError = (value) => {
	return Object.keys(value).length > 0 ? true : false;
};

export function validate(value, flag = 'User_Detail') {
	const errors = {};

	if (!value.nm) {
		errors.nm = '이름을 입력해 주세요.';
	} else if (value.nm.length <= 0 || value.nm.length > 20) {
		errors.nm = '이름은 20자 이하로 입력해 주세요.';
	}
	// if (!value.userid) {
	// 	errors.userid = '아이디를 입력해 주세요.';
	// } else if (!isUserId(value.userid)) {
	// 	errors.userid = '아이디는 4~20자의 알파벳과 숫자로만 이루어져야 합니다.';
	// }
	if (!value.usrty) {
		errors.usrty = '사용자 타입을 입력해 주세요.';
	}
	if (!value.cocd) {
		errors.cocd = '법인코드를 입력해 주세요.';
	} else if (value.cocd.length <= 0 || value.cocd.length > 5) {
		errors.cocd = '법인코드는 5자리 이하로 입력해 주세요.';
	}
	if (!value.custcd) {
		errors.custcd = '업체코드를 입력해 주세요.';
	} else if (value.custcd.length <= 0 || value.custcd.length > 10) {
		errors.custcd = '업체코드는 10자리 이하로 입력해 주세요.';
	}
	if (!value.deptcd) {
		errors.deptcd = '부서코드를 입력해 주세요.';
	} else if (value.deptcd.length <= 0 || value.deptcd.length > 10) {
		errors.deptcd = '부서코드는 10자리 이하로 입력해 주세요.';
	}
	if (!value.empno) {
		errors.empno = '사번을 입력해 주세요.';
	} else if (value.empno.length <= 0 || value.empno.length > 10) {
		errors.empno = '사번은 10자리 이하로 입력해 주세요.';
	}
	// if (!value.regid) {
	// 	errors.regid = '사용자 관리번호를 입력해 주세요.';
	// }
	if (!value.useyn) {
		errors.useyn = '사용유무를 선택해 주세요.';
	}
	if (value.email) {
		if (!isEmail(value.email)) {
			errors.email = '잘못된 이메일 형식입니다.';
		}
	}

	//유저를 등록할 경우 비밀번호 밸리데이션 추가
	if (flag === 'UserAdd') {
		if (!value.pwd) {
			errors.pwd = '비밀번호를 입력해 주세요.';
		} else if (!isPassword(value.pwd)) {
			errors.pwd = '비밀번호는 4~20자 사이의 영문, 숫자, 특수문자를 혼합하여 입력해 주세요.';
		}
	}

	return errors;
}

export function partCompValidate(value) {
	const errors = {};

	if (!value?.sysnm) {
		errors.sysnm = '참여 업체(시스템)명을 입력해 주세요.';
	}
	if (!value?.sysnum) {
		errors.sysnum = '권한명을 입력해 주세요.';
	}

	return errors;
}

export function sysAuthValidate(value) {
	const errors = {};

	if (!value?.rolsys) {
		errors.rolsys = '참여 업체(시스템)명을 입력해 주세요.';
	}
	if (!value?.rolnm) {
		errors.rolnm = '권한명을 입력해 주세요.';
	}
	if (!value?.regid) {
		errors.regid = '권한코드를 입력해 주세요.';
	}

	return errors;
}
