import { types } from 'mobx-state-tree';
import { getMyInfo } from '../page/chat/Service';
import AuthService from '../services/AuthService';
import { performLogout, perfromLogin } from '../lib/Util';

const OFFLINE = 'offline';

export const User = types
	.model('User', {
		email: types.maybe(types.string),
		name: types.maybe(types.string),
		avatarUrl: types.maybe(types.string),
		bio: types.maybe(types.string),
		requirePasswordChange: types.maybe(types.boolean),
		active: types.maybe(types.boolean),
		role: types.maybe(types.string),
		status: types.maybe(types.string),
		statusText: types.maybe(types.string),
	})
	.views((self) => ({
		get me() {
			return {
				...self,
			};
		},
	}))
	.actions((self) => ({
		async authMe(email, userId, authToken, tokenExpires, navigate = null) {
			await AuthService.authMe().then(e => {
				console.log(e)
			})
			await getMyInfo({
				userId,
				authToken,
			})
				.then(async (res) => {
					if (res.data.success === true) {
						console.log('mobx === ', res);
						await self.setData(res.data);
						perfromLogin(email, userId, authToken, tokenExpires, navigate);
					} else {
						performLogout();
					}
				})
				.catch((error) => {
					performLogout();
				});
		},
		async setMyAvatarUrl() {
			self.avatarUrl = `${self.avatarUrl}?t=${new Date().getTime()}`;
		},
		async setStatus(data) {
			self.status = data || OFFLINE;
		},
		async setStatusText(data) {
			self.statusText = data || '';
		},
		async setData(data) {
			self.email = data?.emails?.length > 0 ? data?.emails[0]?.address : '';
			self.name = data?.name || '';
			self.avatarUrl = `${data?.avatarUrl || ''}?t=${new Date().getTime()}`;
			self.bio = data?.bio || '';
			self.requirePasswordChange = data?.requirePasswordChange || false;
			self.active = data?.active || false;
			self.role = data?.roles?.length > 0 ? data?.roles[0] : '';
			self.status = data?.status || OFFLINE;
			self.statusText = data?.statusText || '';
		},
		async logout() {
			performLogout();
			self.email = '';
			self.name = '';
			self.avatarUrl = '';
			self.bio = '';
			self.requirePasswordChange = false;
			self.active = false;
			self.role = '';
			self.status = OFFLINE;
			self.statusText = '';
		},
	}));

export default User;
