import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import { useMst } from '../../models';
import { observer } from 'mobx-react';
import Avatar from '../../components/Avatar';
import { userMenu } from '../../menu';
import { CustomPopovers } from '../../components/UserDetailPopover';
import Collapse from '../../components/bootstrap/Collapse';
import Icon from '../../components/icon/Icon';
import { setStatus } from '../../page/chat/Service';
import { getLoginData } from '../../lib/Util';
import showNotification from '../../components/extras/showNotification';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal';
import Input from '../../components/bootstrap/forms/Input';

const ONLINE = 'online';
const AWAY = 'away';
const BUSY = 'busy';
const OFFLINE = 'offline';
const STATUS_MESSAGE = 'statusMessage';

const UserHeader = observer(() => {
	const navigate = useNavigate();
	const { user } = useMst();
	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [statusText, setStatusText] = useState(user.me.statusText || '');
	const statusList = [ONLINE, AWAY, BUSY, OFFLINE, STATUS_MESSAGE];
	const commonClassName = `d-flex align-items-center gap-1 w-100 ${user.me.status}`;

	const getUserStatusIcon = (status) => {
		switch (status) {
			case ONLINE:
				return { icon: 'Circle', color: 'success', text: '온라인' };
			case AWAY:
				return { icon: 'AccessTime', color: 'warning', text: '자리비움' };
			case BUSY:
				return { icon: 'RemoveCircle', color: 'danger', text: '바쁨' };
			case OFFLINE:
				return { icon: 'Circle', color: 'dark', text: '오프라인' };
			case STATUS_MESSAGE:
				return { icon: 'Accessibility', color: 'primary', text: '상태 메시지' };
			default:
				return { icon: 'Circle', color: 'dark', text: '' };
		}
	};

	const handleSetStatus = async (status) => {
		const loginData = getLoginData();
		const userId = loginData.userId;
		const authToken = loginData.token;
		if (userId && authToken) {
			if (status === STATUS_MESSAGE) {
				setStatusText(user.me.statusText || '');
				setIsModalOpen(true);
			} else {
				await setStatus(status, null, { userId, authToken })
					.then((res) => {
						if (res.success === true) {
							user.setStatus(status);
						}
					})
					.catch((error) => {
						showNotification(
							'사용자 상태변경',
							'사용자 상태를 변경햐는 도중에 문제가 발생하였습니다.',
							'danger',
						);
					});
			}
		}
	};

	const handleSubmitStatusText = async () => {
		const loginData = getLoginData();
		const userId = loginData.userId;
		const authToken = loginData.token;
		await setStatus(null, statusText, { userId, authToken })
			.then((res) => {
				if (res.success === true) {
					user.setStatusText(statusText);
				}
			})
			.catch((error) => {
				showNotification(
					'사용자 상태변경',
					'사용자 상태를 변경햐는 도중에 문제가 발생하였습니다.',
					'danger',
				);
			});
		setIsModalOpen(false);
	};

	const handleOnKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSubmitStatusText();
		}
	};

	const myCurrentStatusIcon = getUserStatusIcon(user.me.status);

	return (
		<>
			<CustomPopovers
				id='CustomPopovers'
				trigger='click'
				desc={
					<div className='my-info-menu'>
						<div>
							<div>
								<Button
									className={`${commonClassName}`}
									onClick={() => {
										setIsCollapseOpen((prev) => !prev);
									}}>
									<Icon icon={myCurrentStatusIcon.icon} color={myCurrentStatusIcon.color} />
									현재상태
								</Button>
							</div>
							<Collapse isOpen={isCollapseOpen}>
								<hr />
								{statusList.map((text, index) => {
									const statusObj = getUserStatusIcon(text);
									const isActive = user.me.status === text;
									return (
										<div key={`${text}-${index}`}>
											<Button
												className={commonClassName}
												isActive={isActive}
												type='submit'
												onClick={() => {
													handleSetStatus(text);
												}}>
												<Icon icon={statusObj.icon} color={statusObj.color} />
												{statusObj.text}
											</Button>
										</div>
									);
								})}
								<hr />
							</Collapse>
						</div>
						<div>
							<Button
								className={commonClassName}
								onClick={async () => {
									navigate(userMenu.settings.subMenu.profileSettings.path);
								}}>
								{/* <Icon icon='person' /> */}
                <i className='ri-account-box-line ri-xl'></i>
								프로필
							</Button>
						</div>
            <div>
							<Button
								className={commonClassName}
								onClick={async () => {
									navigate(userMenu.settings.subMenu.notificationSettings.path);
								}}>
								{/* <Icon icon='ri-notification-line' /> */}
                <i className='ri-settings-4-line ri-xl'></i>
								알림설정
							</Button>
						</div>
						<hr />
						<div>
							<Button
								className={commonClassName}
								onClick={async () => {
									user.logout();
								}}>
								{/* <Icon icon='Logout' /> */}
                <i className='ri-logout-box-r-line ri-xl'></i>
								로그아웃
							</Button>
						</div>
					</div>
				}>
				<div className='d-flex align-items-center p-1' style={{ cursor: 'pointer' }}>
					<div className='position-relative'>
						<Avatar size={30} src={user?.me?.avatarUrl || ''} className='me-2 ' />
						<Icon
							className='position-absolute top-0 end-0'
							icon={myCurrentStatusIcon.icon}
							color={myCurrentStatusIcon.color}
							size='sm'
						/>
					</div>
					<div className='chat-info-user ms-1' style={{ display: 'flex', flexDirection: 'column' }}>
						<div className=''>
							<span className='text-dark mb-0'>{`${user.me.name} ( ${user.me.email} )`}</span>
						</div>
						{user.me.statusText ? (
							<span
								className='d-inline-block text-truncate'
								style={{ maxWidth: '180px', fontSize: '11px' }}>
								{user.me.statusText}
							</span>
						) : (
							<></>
						)}
					</div>
				</div>
			</CustomPopovers>

			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} isCentered>
				<ModalHeader setIsOpen={setIsModalOpen}>상태 메시지</ModalHeader>
				<ModalBody>
					<Input
						type='text'
						value={statusText}
						onChange={(e) => {
							setStatusText(e.target.value);
						}}
						onKeyPress={handleOnKeyPress}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						type='button'
						color='danger'
						onClick={() => {
							setIsModalOpen(false);
						}}>
						닫기
					</Button>
					<Button type='button' color='primary' onClick={handleSubmitStatusText}>
						저장
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
});

export default UserHeader;
