import React from 'react';
import Spinner from '../../../components/bootstrap/Spinner';

const LoadingSpinner = (props) => {
	return (
		<Spinner
			color='dark'
			isGrow
			isSmall
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}></Spinner>
	);
};

export default LoadingSpinner;
