let host = window.location.host.indexOf('localhost') > -1 ? 'local' : window.location.host.replace('chat.teamply.co.kr', 'prod').replace('ccp.kemcluster.com', '')
if (!host) host = 'prod'

console.log('host : ' + host);

const config={
    local: {
      CLIENT_ID: 'kr.co.teamply.chat',
      API_URL: "http://localhost:11061",
      CHAT_URL: "https://rc.teamply.co.kr",
      WEBSOCKET: "wss://rc.teamply.co.kr/websocket",
      KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
      COOKIE_URL: 'localhost',
    },
    dev: {
      CLIENT_ID: 'kr.co.teamply.chat',
      API_URL: "https://chat.teamply.co.kr",
      CHAT_URL: "https://rc.teamply.co.kr",
      WEBSOCKET: "wss://rc.teamply.co.kr/websocket",
      KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
      COOKIE_URL: '.teamply.co.kr',
    },
    prod: {
      CLIENT_ID: 'kr.co.teamply.chat',
      API_URL: "https://chat.teamply.co.kr",
      CHAT_URL: "https://rc.teamply.co.kr",
      WEBSOCKET: "wss://rc.teamply.co.kr/websocket",
      KAKAO_API_KEY: "61ebdc909cc9f97b4a24abc1d72aa4bb",
      COOKIE_URL: '.teamply.co.kr',
    }
}

export default config[host];