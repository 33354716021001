import React, { lazy } from 'react';
import { auth, userMenu } from '../menu';
import Login from '../page/Auth/Login';
import SignUp from '../page/Auth/SignUp';
import FindPW from '../page/Auth/FindPassword';
import Links from '../page/Links';

const CHAT = {
	INDEX: lazy(() => import('../page/chat')),
};
const SETTINGS = {
	PROFILE_SETTINGS: lazy(() => import('../page/Settings/ProfileSettings')),
	NOTIFICATION_SETTINGS: lazy(() => import('../page/Settings/NotificationSettings')),
	WORK_DAILY_REPORT: lazy(() => import('../page/Links')),
};

const presentation = [
	/*
	 *  인증
	 */
	{
		title: 'home',
		path: '/',
		replace: auth.login.path,
		exact: true,
	},
	{
		title: 'Login',
		path: auth.login.path,
		element: <Login />,
		exact: true,
	},
	{
		title: 'Sign-Up',
		path: auth.signUp.path,
		element: <SignUp />,
		exact: true,
	},
	{
		title: 'Find-Password',
		path: auth.findPassword.path,
		element: <FindPW />,
		exact: true,
	},
	/*
	 *  채팅
	 */
	{
		title: '즐겨찾는 사용자',
		path: userMenu.favorite.path,
		element: <CHAT.INDEX />,
		exact: true,
	},
	{
		title: '1:1 채팅',
		path: userMenu.chat.path,
		element: <CHAT.INDEX />,
		exact: true,
	},
	{
		title: '그룹 채팅',
		path: userMenu.group.path,
		element: <CHAT.INDEX />,
		exact: true,
	},
	/*
	 *  설정
	 */
	// {
	// 	title: '프로필',
	// 	path: userMenu.settings.subMenu.profileSettings.path,
	// 	element: <SETTINGS.PROFILE_SETTINGS />,
	// 	exact: true,
	// },
	// {
	// 	title: '알림',
	// 	path: userMenu.settings.subMenu.notificationSettings.path,
	// 	element: <SETTINGS.NOTIFICATION_SETTINGS />,
	// 	exact: true,
	// },
];

const contents = [...presentation];

export default contents;
