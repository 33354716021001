import React, { useCallback, useRef, useState } from 'react';
import Card, { CardBody, CardFooter, CardHeader } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import { isEmail, isMessengerPasswordValid } from '../../lib/checker';
import { useFormik } from 'formik';
import ControlledWizard, { ControlledWizardItem } from '../common/ControlledWizard';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Input from '../../components/bootstrap/forms/Input';
import Icon from '../../components/icon/Icon';
import showNotification from '../../components/extras/showNotification';
import { motion } from 'framer-motion';
import { commonValidationAttribute } from '../../lib/Util';
import moment from 'moment';
import useEmailExpirationTime from '../../hooks/useEmailExpirationTime';
import RocketChatService from '../../services/RocketChatService';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import { auth } from '../../menu';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import LoadingSpinner from './component/LoadingSpinner';
import companyLogo from '../../assets/logo-box.svg'

const EMAIL = 'email';
const IS_SUBMITTED_VERIFICATION_CODE = 'isSubmittedVerificationCode';
const VERIFICATION_CODE = 'verificationCode';
const IS_VERIFICATION_CODE_CONFIRMED = 'isVerificationCodeConfirmed';
const PASSWORD = 'password';
const CONFIRM_PASSWORD = 'confirmPassword';
const FINDPASSWORD = 'FINDPASSWORD';

const LOGIN_PATH = auth.login.path;

const FindPassword = () => {
	const navigate = useNavigate();
	const wizardRef = useRef();
	const { isIntervalRunning, doClearInterval, getExpirationTime, renderRemainingTime } =
		useEmailExpirationTime();
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			[EMAIL]: '',
			[IS_SUBMITTED_VERIFICATION_CODE]: false,
			[VERIFICATION_CODE]: '',
			[IS_VERIFICATION_CODE_CONFIRMED]: false,
			[PASSWORD]: '',
			[CONFIRM_PASSWORD]: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.email) {
				errors.email = '이메일을 입력해주세요.';
			} else if (!isEmail(values.email)) {
				errors.email = '이메일 형식이 올바르지 않습니다.';
			}
			if (!values.verificationCode) {
				errors.verificationCode = '인증코드를 입력해주세요.';
			}
			if (!values.password) {
				errors.password = '비밀번호를 입력해주세요.';
			} else if (!isMessengerPasswordValid(values.password)) {
				errors.password = '비밀번호는 6자리 ~ 35자리 이내로 입력해주세요.';
			}
			if (!values.confirmPassword) {
				errors.confirmPassword = '비밀번호를 입력해주세요.';
			} else if (values.password !== values.confirmPassword) {
				errors.confirmPassword = '비밀번호가 일치하지 않습니다.';
			}

			return errors;
		},
		onSubmit: async (values) => {
			setIsLoading(true);
			await RocketChatService.changePassword({
				...values,
				password: md5(values.password),
				confirmPassword: md5(values.confirmPassword),
			})
				.then((res) => {
					if (res.data.success === true) {
						navigate(LOGIN_PATH);
						showNotification('비밀번호 변경', '비밀번호가 성공적으로 변경되었습니다.', 'success');
					} else {
						showNotification(
							'비밀번호 변경 실패',
							res?.data?.message ||
								'비밀번호 변경 도중에 문제가 발생하였습니다. 나중에 다시 시도해주세요.',
							'danger',
						);
					}
				})
				.catch((error) =>
					showNotification(
						'비밀번호 변경 실패',
						'비밀번호 변경 도중에 문제가 발생하였습니다. 나중에 다시 시도해주세요.',
						'danger',
					),
				)
				.finally(() => setIsLoading(false));
		},
	});

	const handleChange = (event) => {
		const { name, value } = event.target;

		if ([EMAIL, VERIFICATION_CODE, PASSWORD, CONFIRM_PASSWORD].includes(name)) {
			formik.setFieldValue(name, value.trim());
		} else {
			formik.setFieldValue(name, value);
		}
	};

	const handleVerificationCodeSubmitNNextStep = async (isResendEmail) => {
		if (!isEmail(formik.values.email)) {
			formik.validateForm();
			formik.setFieldTouched(EMAIL, true);
			return;
		}
		const serviceSuccess = () =>
			showNotification(
				'인증코드 전송',
				`${formik.values.email}로 비밀번호 초기화 인증코드를 발송하였습니다.
				메일이 도착하는데 시간이 소요될 수 있습니다.`,
				'success',
			);
		const serviceFailed = () =>
			showNotification(
				'인증코드 전송 실패',
				`${formik.values.email}로 인증코드를 전송하는데 실패하였습니다.`,
				'danger',
			);

		await RocketChatService.sendVerificationCodeToEmail(formik.values.email, FINDPASSWORD)
			.then((res) => {
				if (res.data) {
					const backendExpirationTime = res.data.expirationTime;
					formik.setFieldValue(IS_SUBMITTED_VERIFICATION_CODE, true);

					doClearInterval();
					getExpirationTime(backendExpirationTime);

					serviceSuccess();
					!isResendEmail && wizardRef.current.nextStep();
				}
			})
			.catch((err) => {
				serviceFailed();
			});
	};

	const handleVerificationCodeConfirmedNNextStep = async () => {
		//입력된 이메일 확인코드 서버에 전송해서 맞는지 확인
		//result true로 받으면 다음 프로세스 진행
		if (formik.errors.verificationCode) {
			formik.validateForm();
			formik.setFieldTouched(VERIFICATION_CODE, true);
			return;
		}

		await RocketChatService.confirmVerificationCode(
			formik.values.email,
			FINDPASSWORD,
			formik.values.verificationCode,
		)
			.then((res) => {
				if (res.data.success === true) {
					formik.setFieldValue(IS_VERIFICATION_CODE_CONFIRMED, true);

					// interval이 작동하고 있을 수 있기 때문에 초기화 시켜줌
					doClearInterval();

					wizardRef.current.nextStep();
				} else if (res.data.success === false) {
					showNotification(
						'이메일 인증',
						res?.data?.message || '유효하지 않은 인증코드입니다. 다시 시도해주세요.',
						'warning',
					);
				}
			})
			.catch((error) => console.error(error));
	};

	return (
		<PageWrapper title={auth.findPassword.text} className={'login-bg'}>
			<Page>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8'>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.5 }}>
							<Card className='card-box shadow' data-tour='login-page'>
                <CardHeader className='card-box-head flex-column mt-5'>
                  <img src={companyLogo} alt="logo" className='logo'  />
									<div className='text-center mt-5'>
                    <div>비밀번호를 잊어버리셨군요!</div>
										<div>회원님의 등록된 이메일 주소를 입력바랍니다.</div>
                  </div>
								</CardHeader>								
								<CardBody className='row card-box-body'>
									<div className='text-center mb-3'>
										
									</div>
									<form onSubmit={formik.handleSubmit}>
										<ControlledWizard
											ref={wizardRef}
											isHeader
											isHideButtons
											headerIcon='SelfImprovement'
											color='primary'>
											<ControlledWizardItem id='inputEmail' title='이메일 입력'>
												<div className='mb-3'>
													<InputGroup>														
														<Input
															id={EMAIL}
															type='email'
                              className='formstb-line'
															placeholder='가입한 이메일 주소'
															value={formik.values.email}
															disabled={formik.values.isVerificationCodeConfirmed}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, EMAIL)}
														/>
													</InputGroup>
												</div>
												<Button
													color='primary'
													className='col-12 py-3 mt-3'
													disabled={formik.errors.email}
													onClick={() => handleVerificationCodeSubmitNNextStep(false)}>
													{formik.values.isSubmittedVerificationCode
														? '인증코드 재전송'
														: '인증코드 전송'}
												</Button>
											</ControlledWizardItem>

											<ControlledWizardItem id='inputVarificationCode' title='인증코드 입력'>
												<div className='mb-3'>
													<InputGroup>
														{/* <InputGroupText className='bg-white'>
															<Icon icon='MarkEmailRead' size='lg' />
														</InputGroupText> */}
														<Input
															id={VERIFICATION_CODE}
															type='text'
                              className='formstb-line'
															placeholder='인증코드 입력'
															value={formik.values.verificationCode}
															disabled={
																!formik.values.isSubmittedVerificationCode ||
																formik.values.isVerificationCodeConfirmed
															}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, VERIFICATION_CODE)}
														/>
														<Button
															color='dark'
															disabled={
																formik.errors.email || formik.values.isVerificationCodeConfirmed
															}
															onClick={() => handleVerificationCodeSubmitNNextStep(true)}>
															인증코드 재전송
														</Button>
													</InputGroup>
													{renderRemainingTime(
														formik.values.isSubmittedVerificationCode &&
															!formik.values.isVerificationCodeConfirmed,
													)}
												</div>
												<Button
													color='primary'
													className='col-12 py-3 mt-3'
													disabled={
														!formik.values.isSubmittedVerificationCode ||
														formik.values.isVerificationCodeConfirmed ||
														formik.errors.verificationCode ||
														!isIntervalRunning
													}
													onClick={handleVerificationCodeConfirmedNNextStep}>
													인증코드 확인
												</Button>
											</ControlledWizardItem>

											<ControlledWizardItem id='inputNewPassword' title='새 비밀번호 입력'>
												<div className='mb-2'>
													<InputGroup>
														<InputGroupText className='bg-white'>
															<Icon icon='Lock' size='lg' />
														</InputGroupText>
														<Input
															id={PASSWORD}
															type='password'
															passwordVisibleIcon
															placeholder='새 비밀번호 입력'
															value={formik.values.password}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, PASSWORD)}
														/>
													</InputGroup>
												</div>
												<div className='mb-2'>
													<InputGroup>
														<InputGroupText className='bg-white'>
															<Icon icon='Lock' size='lg' />
														</InputGroupText>
														<Input
															id={CONFIRM_PASSWORD}
															type='password'
															passwordVisibleIcon
															placeholder='새 비밀번호 확인'
															value={formik.values.confirmPassword}
															onChange={handleChange}
															//eslint-disable-next-line react/jsx-props-no-spreading
															{...commonValidationAttribute(formik, CONFIRM_PASSWORD)}
														/>
													</InputGroup>
												</div>
												<Button
													color='primary'
													className='col-12 py-3 d-flex justify-content-center align-items-center gap-1'
													type='submit'
													disabled={isLoading}
													isDisable={!formik.isValid && !!formik.submitCount}>
													<div>비밀번호 변경</div>
													{isLoading ? <LoadingSpinner /> : <></>}
												</Button>
											</ControlledWizardItem>
										</ControlledWizard>
									</form>
								</CardBody>
								<CardFooter className='card-box-foot pt-0'>
									<Button
										icon='ArrowBack'
                    className='f-n'
										onClick={() => {
											navigate(LOGIN_PATH);
										}}>
										로그인 화면으로
									</Button>
								</CardFooter>
							</Card>
						</motion.div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default FindPassword;
